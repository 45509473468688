<template>
  <table-view>
    <template #card-header>
      <h3 class="card-title">Recycle Air Conditioner</h3>
      <div class="row">
        <search-input
          class="ml-auto"
          placeholder="Model, Resource, Sub resource"
          v-model="searchParam"
          @input="fetchAllDeletedAC()"
          @change-input="changeSearchVal"
        ></search-input>
      </div>
    </template>
    <template #table-header>
      <th>Sr No.</th>
      <th>Model No.</th>
      <th>Unique Name</th>
      <th>Capacity</th>
      <th>Location</th>
      <th>Building</th>
      <th>Actions</th>
    </template>
    <template #default>
      <tr v-for="(ac, index) in allDeletedAC" :key="ac.ac_id">
        <td>{{ index+1 }}</td>
        <td>{{ ac.model_number }}</td>
        <td>{{ ac.unique_alias }}</td>
        <td>{{ ac.capacity }}</td>
        <td>
          {{ ac.resource_name }}
          <span v-if="ac.sub_resource_name"
            >-> {{ ac.sub_resource_name }}</span
          >
        </td>
        <td>{{ ac.building_name }}</td>
        <td>
          <span @click="recycleHandler(ac.ac_id)" class="show-pointer">
            <i
              class="fa-solid fa-recycle p-2"
              style="color: rgb(12 154 49); font-size: 22px"
              title="Recycle"
            ></i>
          </span>
        </td>
      </tr>
    </template>
  </table-view>
  <custom-pagination
    :prev-page="prevPage"
    :next-page="nextPage"
    @on-update="paginationDataUpdate"
  ></custom-pagination>
</template>
<script>
import TableView from "../UI/TableView.vue";
import SearchInput from "../UI/SearchInput.vue";
import CustomPagination from "../shared/pagination/CustomPagination.vue";
import debounce from "lodash.debounce";
export default {
  components: { TableView, SearchInput, CustomPagination },
  data() {
    return {
      allDeletedAC: [],
      searchParam: "",
      currentPage: 1,
      pageSize: 5,
      prevPage: false,
      nextPage: false,
    };
  },
  methods: {
    changeSearchVal(input) {
      this.searchParam = input;
    },
    fetchAllDeletedAC: debounce(function () {
      const searchBody = {
        size: this.pageSize,
        page: this.currentPage,
        searchData: { searchStr: this.searchParam },
      };
      this.axios.post(
        this.$Constants.BaseUrl + this.$Constants.ApiEndpoints.FETCH_DELETED_AC,
        searchBody
      ).then(res=>{
        if(res.status===200){
            this.allDeletedAC = res.data.data;
            this.prevPage = res.data.prevPage;
            this.nextPage = res.data.nextPage;
        }
        else{
          this.prevPage = false;
            this.nextPage = false;
            this.allDeletedStaff = [];
            this.$Methods.toastWarning("No Data Found");
        }
      }).catch(err=>{
        this.$Methods.toastError(err.response.data.message);
      });
    }, 300),
    paginationDataUpdate(currentPage, pageSize){
      this.currentPage = currentPage ;
      this.pageSize = pageSize;
      this.fetchAllDeletedAC();
    },
    recycleHandler(id) {
      this.axios
        .patch(
          this.$Constants.BaseUrl +
            this.$Constants.ApiEndpoints.RESTORE_AC +
            "/" +
            id
        )
        .then((res) => {
          if (res.status === 200) {
            this.$Methods.toastSuccess(res.data.message);
            this.fetchAllDeletedAC();
          }
        })
        .catch((err) => {
          this.$Methods.toastError(err.response.data.message);
        });
    },
  },
  mounted() {
    this.fetchAllDeletedAC();
  },
};
</script>
