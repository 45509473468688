<template>
  <table-view>
    <template #card-header>
      <h3 class="card-title">Recycle Department</h3>
    </template>
    <template #table-header>
      <th>Sr No.</th>
      <th>Department</th>
      <th>Building</th>
      <th>Created By</th>
      <th>Actions</th>
    </template>
    <template #default>
      <tr v-for="(dept, index) in allDeletedDepartment" :key="dept.dept_id">
        <td>{{ index + 1 }}</td>
        <td>{{ dept.department_name }}</td>
        <td>{{ dept.building_name }}</td>
        <td>{{ dept.name }}</td>
        <td>
          <span @click="recycleHandler(dept.dept_id)" class="show-pointer"
            ><i
              class="fa-solid fa-recycle p-2"
              style="color: rgb(12 154 49); font-size: 22px"
            ></i>
          </span>
        </td>
      </tr>
    </template>
  </table-view>
</template>

<script>
import TableView from "../UI/TableView.vue";
export default {
  components: { TableView },
  data() {
    return {
      allDeletedDepartment: {},
    };
  },
  methods: {
    fetchAllDeletedDepartment() {
      this.axios
        .get(
          this.$Constants.BaseUrl +
            this.$Constants.ApiEndpoints.FETCH_DELETED_DEPARTMENT
        )
        .then((res) => {
          if (res.status === 200) {
            this.allDeletedDepartment = res.data.data;
          }
        })
        .catch((err) => {
          this.$Methods.toastError(err.response.data.message);
        });
    },
    recycleHandler(id) {
      this.axios
        .patch(
          this.$Constants.BaseUrl +
            this.$Constants.ApiEndpoints.RESTORE_DEPARTMENT +
            "/" +
            id
        )
        .then((res) => {
          if (res.status === 200) {
            this.$Methods.toastSuccess(res.data.message);
            this.fetchAllDeletedDepartment();
          }
        })
        .catch((err) => {
          this.$Methods.toastError(err.response.data.message);
        });
    },
  },
  mounted() {
    this.fetchAllDeletedDepartment()
  },
};
</script>
