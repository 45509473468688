<template>
  <table-view :isLoading="isLoading">
    <template #card-header>
      <h3 class="card-title">All Air-Conditioners</h3>
      <div class="row">
        <div class="col-1 ml-auto">
          <button class=" btn btn-secondary ml-4" @click="filterViewHandler()">
            <i class="fa-solid fa-filter"></i>
          </button>
        </div>
        <search-input
          placeholder="Model, Resource, Sub resource"
          v-model="searchParam"
          @input="searchAC()"
          @change-input="changeSearchVal"
        ></search-input>
        <div class="col-2">
          <router-link
            to="/viewAllCompany"
            class="btn btn-block bg-gradient-info"
          >
            AC Companies
          </router-link>
        </div>
        <div class="col-2">
          <router-link
            to="/addNewAirConditioner"
            class="btn btn-block bg-gradient-success"
          >
            Add New
          </router-link>
        </div>
      </div>
      <ac-filter v-if="showFilter" @building-on-change="changeSelectedBuilding"></ac-filter>
    </template>
    <template #table-header>
      <th>Sr No.</th>
      <th>Model No.</th>
      <th>Unique Name</th>
      <th>AC capacity</th>
      <th>Location</th>
      <th>Building</th>
      <th>Actions</th>
    </template>
    <template #default>
      <tr v-for="(airCond, index) in allAirConditioners" :key="airCond.ac_id">
        <td>{{ index + 1 }}</td>
        <td v-if="airCond.model_number">{{ airCond.model_number }}</td>
        <td v-else>-</td>
        <td>{{ airCond.unique_alias }}</td>
        <td>{{ airCond.ac_capacity }}</td>

        <td>
          {{ airCond.resource_name }}
          <span v-if="airCond.sub_resource_name"
            >-> {{ airCond.sub_resource_name }}</span
          >
        </td>
        <td>{{ airCond.building_name }}</td>
        <td>
          <span
            class="show-pointer"
            v-if="airCond.ac_bill_image"
            title="View Bill"
          >
            <a
              :href="airCond.ac_bill_image"
              target="_blank"
              rel="noopener noreferrer"
            >
              <i
                class="fas fa-eye p-2"
                style="color: rgb(0, 102, 255); font-size: 22px"
              ></i>
            </a>
          </span>
          <span @click="editHandler(airCond.ac_id)" class="show-pointer">
            <i
              class="fas fa-edit p-2"
              style="color: rgb(255, 153, 0); font-size: 22px"
            ></i>
          </span>
          <span @click="deleteHandler(airCond.ac_id)" class="show-pointer">
            <i class="fas fa-trash p-2" style="color: red; font-size: 22px"></i>
          </span>
        </td>
      </tr>
    </template>
  </table-view>
  <custom-pagination :prev-page="prevPage" :next-page="nextPage " @on-update="paginationDataUpdate"/>
</template>
<script>
import SearchInput from "../UI/SearchInput.vue";
import TableView from "../UI/TableView.vue";
import axios from "axios";
import debounce from "lodash.debounce";
import AcFilter from "./AcFilter.vue";
import CustomPagination from "../shared/pagination/CustomPagination.vue";
export default {
  components: {
    TableView,
    SearchInput,
    AcFilter,
    CustomPagination
  },
  data() {
    return {
      isLoading:false,
      currentPage:1,
      pageSize:5,
      prevPage:false,
      nextPage:false,
      allAirConditioners: {},
      searchParam: null,
      showFilter:false,
      selectedBuildingFilter:[],
    };
  },

  methods: {
    changeSearchVal(input) {
      this.searchParam = input;
    },
    filterViewHandler(){
      this.showFilter = !this.showFilter;
    },
    paginationDataUpdate(currentPage, pageSize){
      this.currentPage = currentPage ;
      this.pageSize = pageSize;
      this.searchAC();
    },
    searchAC: debounce(function () {
        this.isLoading = true;
        let searchBody = {
          page:this.currentPage,
          size:this.pageSize,
          searchData:{
            searchStr:this.searchParam,
            buildings:this.selectedBuildingFilter ? this.selectedBuildingFilter : [] 
          }
        };
        this.axios
          .post(
            this.$Constants.BaseUrl +
              this.$Constants.ApiEndpoints.SEARCH_AC ,
              searchBody
          )
          .then((res) => {
            if (res.status === 200) {
              this.isLoading = false;
              this.allAirConditioners = res.data.data;
              this.prevPage = res.data.prevPage;
              this.nextPage = res.data.nextPage;
            } else if (res.status === 204) {
              this.allAirConditioners = {};
            }
          })
          .catch((err) => {
            this.isLoading = false;
            this.$Methods.toastError(err.response.data.message);            
          });
    }, 300),

    editHandler(id) {
      this.$router.push(`/editAirConditioner/${id}`);
    },
    deleteHandler(id) {
      axios
        .delete(
          this.$Constants.BaseUrl +
            this.$Constants.ApiEndpoints.AIR_CONDITIONER_API +
            "/" +
            id,
        )
        .then((res) => {
          if (res.status === 200) {
            this.$Methods.toastSuccess(res.data.message);
            this.searchAC();
          } else {
            this.$Methods.toastError(res.data.message);
          }
        })
        .catch((err) => {
          if (err.response.status === 409) {
            this.$Methods.errorAlert(
              "This data is connected to some other data! please remove that and try again"
            );
          } else {
            this.$Methods.toastError(err.response.data.message);
          }
        });
    },
    changeSelectedBuilding(data){
      this.selectedBuildingFilter = data;
      this.searchAC();
    }
  },
  mounted() {
    this.searchAC();
  },
};
</script>
