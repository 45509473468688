<template>
  <ul class="nav nav-tabs">
    <li class="nav-item">
      <a
        class="nav-link show-pointer"
        :class="{ active: componentName === 'RecycleStaff' }"
        @click="handleComponentChange('RecycleStaff')"
        >Staff</a
      >
    </li>
    <li class="nav-item">
      <a
        class="nav-link show-pointer"
        :class="{ active: componentName === 'RecycleAirConditioner' }"
        @click="handleComponentChange('RecycleAirConditioner')"
        >Air Conditioner</a
      >
    </li>
    <li class="nav-item">
      <a
        class="nav-link show-pointer"
        :class="{ active: componentName === 'RecycleResource' }"
        @click="handleComponentChange('RecycleResource')"
        >Resource</a
      >
    </li>
    <li class="nav-item">
      <a
        class="nav-link show-pointer"
        :class="{ active: componentName === 'RecycleSubResource' }"
        @click="handleComponentChange('RecycleSubResource')"
        >Sub Resource</a
      >
    </li>
    <li class="nav-item">
      <a
        class="nav-link show-pointer"
        :class="{ active: componentName === 'RecycleBuilding' }"
        @click="handleComponentChange('RecycleBuilding')"
        >Building</a
      >
    </li>
    <li class="nav-item">
      <a
        class="nav-link show-pointer"
        :class="{ active: componentName === 'RecycleDepartment' }"
        @click="handleComponentChange('RecycleDepartment')"
        >Department</a
      >
    </li>
    <li class="nav-item">
      <a
        class="nav-link show-pointer"
        :class="{ active: componentName === 'RecycleCompany' }"
        @click="handleComponentChange('RecycleCompany')"
        >Company</a
      >
    </li>
  </ul>
  <keep-alive>
    <component :is="componentName"></component>
  </keep-alive>
</template>
<script>
import RecycleStaff from "./RecycleStaff.vue";
import RecycleAirConditioner from "./RecycleAirConditioner.vue";
import RecycleResource from "./RecycleResource.vue";
import RecycleSubResource from "./RecycleSubResource.vue";
import RecycleBuilding from "./RecycleBuilding.vue";
import RecycleDepartment from "./RecycleDepartment.vue";
import RecycleCompany from "./RecycleCompany.vue";
export default {
  components: {
    RecycleStaff,
    RecycleAirConditioner,
    RecycleResource,
    RecycleSubResource,
    RecycleBuilding,
    RecycleDepartment,
    RecycleCompany,
  },
  data() {
    return {
      componentName: "RecycleStaff",
    };
  },
  methods: {
    handleComponentChange(cmpName) {
      this.componentName = cmpName;
    },
  },
};
</script>
