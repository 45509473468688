<template>
  <table-view>
    <template #card-header>
      <h3 class="card-title">Recycle Resource</h3>
      <div class="row">
        <search-input
          class="ml-auto"
          placeholder="Name, Building, Category"
          v-model="searchParam"
          @input="fetchAllDeletedResource()"
          @change-input="changeSearchVal"
        ></search-input>
      </div>
    </template>
    <template #table-header>
      <th>Sr No.</th>
      <th>Resource Name</th>
      <th>Building</th>
      <th>Type</th>
      <th>Category</th>
      <th>Actions</th>
    </template>
    <template #default>
      <tr
        v-for="(resource, index) in allDeletedResource"
        :key="resource.resource_id"
      >
        <td>{{ index + 1 }}</td>
        <td>{{ resource.resource_name }}</td>
        <td>{{ resource.building_name }}</td>
        <td>{{ resource.resource_type }}</td>
        <td>{{ resource.category_name }}</td>
        <td>
          <span
            @click="recycleHandler(resource.resource_id)"
            class="show-pointer"
          >
            <i
              class="fa-solid fa-recycle p-2"
              style="color: rgb(12 154 49); font-size: 22px"
              title="Recycle"
            ></i>
          </span>
        </td>
      </tr>
    </template>
  </table-view>
  <CustomPagination
    :prev-page="prevPage"
    :next-page="nextPage"
    :size="pageSize"
    @on-update="paginationDataUpdate"
  ></CustomPagination>
</template>
<script>
import TableView from "../UI/TableView.vue";
import SearchInput from "../UI/SearchInput.vue";
import CustomPagination from "../shared/pagination/CustomPagination.vue";
import debounce from "lodash.debounce";
export default {
  components: { TableView, SearchInput, CustomPagination },
  data() {
    return {
      currentPage: 1,
      pageSize: 5,
      prevPage: false,
      nextPage: false,
      searchParam: "",
      allDeletedResource: [],
    };
  },
  methods: {
    changeSearchVal(input) {
      this.searchParam = input;
    },
    paginationDataUpdate(currentPage, pageSize) {
      this.currentPage = currentPage;
      this.pageSize = pageSize;
      this.fetchAllDeletedResource();
    },
    fetchAllDeletedResource: debounce(function () {
      let searchBody = {
        page: this.currentPage,
        size: this.pageSize,
        searchData: {
          search_str: this.searchParam,
        },
      };

      this.axios
        .post(
          this.$Constants.BaseUrl +
            this.$Constants.ApiEndpoints.FETCH_DELETED_RESOURCE,
          searchBody
        )
        .then((res) => {
          if (res.status === 200) {
            this.allDeletedResource = res.data.data;
            this.prevPage = res.data.prevPage;
            this.nextPage = res.data.nextPage;
          } else {
            this.allDeletedResource = [];
            this.prevPage = false;
            this.nextPage = false;
          }
        })
        .catch((err) => {
          this.$Methods.toastError(err.response.data.message);
        });
    }, 300),
    recycleHandler(id) {
      this.axios
        .patch(
          this.$Constants.BaseUrl +
            this.$Constants.ApiEndpoints.RESTORE_RESOURCE +
            "/" +
            id
        )
        .then((res) => {
          if (res.status === 200) {
            this.$Methods.toastSuccess(res.data.message);
            this.fetchAllDeletedResource();
          }
        })
        .catch((err) => {
          this.$Methods.toastError(err.response.data.message);
        });
    },
  },

  mounted() {
    this.fetchAllDeletedResource();
  },
};
</script>
