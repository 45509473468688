<template>
  <table-view>
    <template #card-header>
      <h3 class="card-title">Recycle Department</h3>
    </template>
    <template #table-header>
      <th>Sr No.</th>
      <th>Company Name</th>
      <th>AC Count</th>
      <th>Actions</th>
    </template>
    <tr v-for="(company, index) in allDeleteCompanies" :key="company.company_id">
      <td>{{ index + 1 }}</td>
      <td>{{ company.company_name }}</td>
      <td>{{ company.ac_count }}</td>
      <td>
        <span @click="recycleHandler(company.company_id)" class="show-pointer"
          ><i
            class="fa-solid fa-recycle p-2"
            style="color: rgb(12 154 49); font-size: 22px"
          ></i>
        </span>
      </td>
    </tr>
  </table-view>
</template>

<script>
import TableView from "../UI/TableView.vue";
export default {
  components: { TableView },
  data() {
    return {
      allDeleteCompanies: [],
    };
  },
  methods: {
    fetchAllDeletedCompanies() {
      this.axios
        .get(
          this.$Constants.BaseUrl +
            this.$Constants.ApiEndpoints.FETCH_DELETED_COMPANY
        )
        .then((res) => {
          if (res.status === 200) {
            this.allDeleteCompanies = res.data.data;
          }
        })
        .catch((err) => {
          this.$Methods.toastError(err.response.data.message);
        });
    },
    recycleHandler(id) {
      this.axios
        .patch(
          this.$Constants.BaseUrl +
            this.$Constants.ApiEndpoints.RESTORE_COMPANY +
            "/" +
            id
        )
        .then((res) => {
          if (res.status === 200) {
            this.$Methods.toastSuccess(res.data.message);
            this.fetchAllDeletedCompanies();
          }
        })
        .catch((err) => {
          this.$Methods.toastError(err.response.data.message);
        });
    },
  },
  mounted() {
    this.fetchAllDeletedCompanies();
  },
};
</script>
