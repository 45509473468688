<template>
  <table-view>
    <template #card-header>
      <h3 class="card-title">Recycle Buildings</h3>
    </template>
    <template #table-header>
      <th>Sr No.</th>
      <th>Building</th>
      <th>Alias</th>
      <th>Created By</th>
      <th>Actions</th>
    </template>
    <template #default>
      <tr
        v-for="(building, index) in allDeletedBuildings"
        :key="building.building_id"
      >
        <td>{{ index + 1 }}</td>
        <td>{{ building.building_name }}</td>
        <td>{{ building.alias }}</td>
        <td>{{ building.name }}</td>
        <td>
          <span
            @click="recycleHandler(building.building_id)"
            class="show-pointer"
          >
            <i
              class="fa-solid fa-recycle p-2"
              style="color: rgb(12 154 49); font-size: 22px"
              title="Recycle"
            ></i>
          </span>
        </td>
      </tr>
    </template>
  </table-view>
</template>
<script>
import TableView from "../UI/TableView.vue";
export default {
  components: { TableView },
  data() {
    return {
      allDeletedBuildings: [],
    };
  },
  methods: {
    fetchAllDeletedBuildings() {
      this.axios
        .get(
          this.$Constants.BaseUrl +
            this.$Constants.ApiEndpoints.FETCH_DELETED_BUILDING
        )
        .then((res) => {
          if (res.status === 200) {
            this.allDeletedBuildings = res.data.data;
          }
        })
        .catch((err) => {
          this.$Methods.toastError(err.response.data.message);
        });
    },
    recycleHandler(id) {
      this.axios
        .patch(
          this.$Constants.BaseUrl +
            this.$Constants.ApiEndpoints.RESTORE_BUILDING +
            "/" +
            id
        )
        .then((res) => {
          if (res.status === 200) {
            this.$Methods.toastSuccess(res.data.message);
            this.fetchAllDeletedBuildings();
          }
        })
        .catch((err) => {
          this.$Methods.toastError(err.response.data.message);
        });
    },
  },
  mounted() {
    this.fetchAllDeletedBuildings();
  },
};
</script>
